import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react'

type Props = {
  activeFilters: number[]
  setActiveFilters: Dispatch<number[]>
}

const ActiveFiltersContext = createContext<Props>({} as Props)

const ActiveFiltersProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [activeFilters, setActiveFilters] = useState<number[]>([])

  return (
    <ActiveFiltersContext.Provider value={{ activeFilters, setActiveFilters }}>
      {children}
    </ActiveFiltersContext.Provider>
  )
}

const useActiveFilters = () => useContext(ActiveFiltersContext)

export { ActiveFiltersProvider, useActiveFilters }
