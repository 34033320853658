import { emptyItem } from 'src/components/adminForms/initials'
import { paymentTypes } from 'src/utils/payment-types'

export type TRules = {
  dev: boolean
  test: boolean
  prod: boolean
  stage: boolean
}
export const initValues = {
  paymentType: emptyItem,
}

// Temporary paymentTypes excluded with Continue Replacement option

export const pTypes = (isContinueReplacement: boolean) => {
  if (isContinueReplacement) {
    return [
      ...paymentTypes,
      //Remove this if protobuf gets updated.
      {
        id: '5',
        value: 'Continue Replacement',
        label: 'Continue Replacement',
      },
    ]
  } else {
    return paymentTypes
  }
}
export const labelsKeys = {
  dispatchID: 'DISPATCH_ID_LABEL',
  contractID: 'CONTRACT_ID_LABEL',
  orderID: 'OUTRIGHT_PAYMENT_ORDER_NUMBER_LABEL',
  firstName: 'OUTRIGHT_PAYMENT_FIRST_NAME_LABEL',
  lastName: 'OUTRIGHT_PAYMENT_LAST_NAME_LABEL',
  billingState: 'OUTRIGHT_PAYMENT_STATE_LABEL',
  vendor: 'OUTRIGHT_PAYMENT_VENDOR_LABEL',
  date: 'OUTRIGHT_PAYMENT_ORDER_DATE_LABEL',
  sku: 'OUTRIGHT_PAYMENT_SKU_LABEL',
  tenantAbbreviation: 'OUTRIGHT_PAYMENT_TENANT_ABBREVIATION_LABEL',
}

export const isContinueReplacement = (env: string, rules: TRules) => {
  if (env === 'development' && rules.dev) {
    return true
  }
  if (env === 'test' && rules.test) {
    return true
  }
  if (env === 'staging' && rules.stage) {
    return true
  }
  if (env === 'production' && rules.prod) {
    return true
  }
  return false
}
