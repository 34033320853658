import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react'
import { api } from 'src/utils/api'
import { MenuItem } from 'src/utils/shared-types'

type Props = {
  getApplianceCategories: () => void
  applianceCategories: MenuItem[]
  categoriesLoading: boolean
}

const ApplianceCategoriesContext = createContext<Props>({} as Props)

const ApplianceCategoriesProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<MenuItem[]>([])

  const fetchCategories = async () => {
    setLoading(true)
    try {
      const { mappedItemIDs } = await api.getApplianceCategories()

      const categoriesArray = mappedItemIDs.map((category) => {
        const categoryOption = {
          id: category.ID as string,
          value: category.ID as string,
          label: category.name as string,
        }

        return categoryOption
      })
      setLoading(false)
      setCategories(
        categoriesArray.filter(
          ({ id }) =>
            id !== 'e384ffec-dd94-44be-90a7-69c1ed7d7166' &&
            id !== '01546c0a-63b9-4093-ba69-8bd3d4cb6940'
        )
      )
    } catch (err) {
      console.error('get categories failed', err)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <ApplianceCategoriesContext.Provider
      value={{
        categoriesLoading: loading,
        applianceCategories: categories,
        getApplianceCategories: fetchCategories,
      }}
    >
      {children}
    </ApplianceCategoriesContext.Provider>
  )
}

const useApplianceCategoriesContext = () =>
  useContext(ApplianceCategoriesContext)

export { ApplianceCategoriesProvider, useApplianceCategoriesContext }
