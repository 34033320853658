import { RefObject } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useAppContext } from '@ftdr/blueprint-components-react'
import { useHistory } from 'react-router'
import { formatPriceToCents } from 'src/utils/internationalization-helper'
import { stripeMinPaymentCents } from 'src/utils/helper'
import {
  AgregatedProcessPaymentResponse,
  PaymentsMethodMfeExposedApi,
} from '@ftdr/payment-method-micro-frontend'
import { payment3_paymentmethodpb } from '@ftdr/payment3_paymentmethod_coordinator-js-client'
import { api } from 'src/utils/api'
import {
  appliancepb,
  google,
} from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { IForm } from '../utils/shared-types'

import { usePaymentContext } from 'src/hooks/use-payment-context'

export const usePayments = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const history = useHistory()
  const { setStatus, setErrorMsg } = usePaymentContext()

  const stripeErrMsgConversion = (error: string | null | undefined): string => {
    switch (error) {
      case "Your card's security code is incorrect.": {
        return localizedText('STRIPE_PAYMENT_FAILED_INVALID_CVV')
      }
      case 'Your card number is invalid.': {
        return localizedText('STRIPE_PAYMENT_FAILED_INVALID_CARD')
      }
      default: {
        return localizedText('STRIPE_PAYMENT_FAILED_GENERIC')
      }
    }
  }

  const handleStripeResponse = async (
    res: AgregatedProcessPaymentResponse,
    recordId: string,
    amount: string,
    orderData: IForm
  ) => {
    if (res.stripe?.type === 'stripe_success') {
      const paymentMethodID =
        res.stripe.stripeSuccessResponse?.paymentMethod?.paymentMethodID
      const totalAmount: appliancepb.IPriceSetter = {
        apply: true,
        price: formatPriceToCents(amount),
      }

      const paymentDetails: appliancepb.IPaymentDetails = {
        ID: paymentMethodID,
        paymentProcessor: appliancepb.PaymentDetails.processor.STRIPE,
        totalPrice: totalAmount,
      }
      const customerBillingState: appliancepb.IAddress = {
        state: orderData.billingState.id,
      }
      const contract: appliancepb.IContract | null = orderData.contractID
        ? { id: orderData.contractID }
        : null
      const customer: appliancepb.ICustomer = {
        firstName: orderData.firstName,
        lastName: orderData.lastName,
        billingAddress: customerBillingState,
      }
      const time: google.protobuf.ITimestamp = {
        seconds: orderData.date!.getTime() / 1000,
      }
      const orderNumber: appliancepb.IOrder = {
        vendorOrderNumber: orderData.orderID,
      }

      const data: appliancepb.IOutrightPaymentRequest = {
        vendor: orderData.vendor.id,
        modelNumber: orderData.modelNumber.toUpperCase(),
        orderDate: time,
        recordId,
        customer, //verify costomer id is sent
        paymentDetails,
        contract,
        paymentType: +orderData.paymentType
          .id as appliancepb.OutrightPaymentRequest.PaymentType,
        dispatchId: orderData.dispatchID,
        order: orderNumber,
      }

      try {
        await api.createOutrightPayment(data)
        setStatus('success')
      } catch (err) {
        console.log(err)
        let stripeErrMsg = stripeErrMsgConversion(
          res.stripe.stripeFailureResponse?.message
        )

        setErrorMsg(stripeErrMsg)

        setStatus('payment error')

        return
      }
    }
    if (res.stripe?.type === 'stripe_failure') {
      let stripeErrMsg = stripeErrMsgConversion(
        res.stripe.stripeFailureResponse?.message
      )

      setErrorMsg(stripeErrMsg)

      setStatus('payment error')

      return
    }
  }
  const submitStripePayment = async (
    amount: string,
    submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi | null>,
    orderData: any
  ) => {
    const replacementId = uuidv4()
    if (formatPriceToCents(amount) >= stripeMinPaymentCents) {
      setStatus('loading')
      try {
        const result = await submitPaymentRef.current!.processPayment({
          stripe: {
            owner: {
              ownerIdentity: {
                type:
                  payment3_paymentmethodpb.OwnerIdentityType
                    .ReplacementIdentityType,
                replacementIdentity: {
                  replacementID: replacementId,
                },
              },
            },
          },
        })

        result &&
          (await handleStripeResponse(result, replacementId, amount, orderData))
      } catch (err) {
        setStatus('payment error')
      } finally {
        history.push('/quick-payment')
      }
    } else {
      setStatus('payment error')
      setErrorMsg(localizedText('STRIPE_PAYMENT_MIN_DOLLAR_ERROR'))
    }
  }

  return {
    submitStripePayment,
  }
}
