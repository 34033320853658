import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AppState, MenuItem } from 'src/utils/shared-types'
import { initValues } from './initial-values'
import { labelsKeys } from '../../form/default-values'
import {
  DateTimeInput,
  IconButton,
  IconSearch,
  Input,
  Select,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import { emptyItem } from 'src/components/adminForms/initials'
import { useGetVendors } from 'src/hooks/use-get-vendors'
import ShoppingCart from '../../shopping-cart/shopping-cart'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import { api } from 'src/utils/api'
import { continueReplacementSchema } from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePaymentContext } from 'src/hooks/use-payment-context'
import { PaymentsMethodMfeExposedApi } from '@ftdr/payment-method-micro-frontend'
import PaymentsForm from '../../stripe-sub-form/stripe-sub-form'
import { usePayments } from 'src/hooks/use-temp-payments'
import { Agreements } from '../../agreements/agreements'
import Warning from '../../warning/warning'
import { useSelector } from 'react-redux'
import { useFormsContext } from 'src/hooks/use-forms-context'
import AddFee from '../../add-fee/add-fee'
import usePaymentOptions from 'src/hooks/use-payment-options'
import { regexDigit } from 'src/utils/validation-utils'
import CartAvailabilityModal from '../../cart-availability-modal/cart-availability-modal'

interface IForm {
  dispatchID: string
  orderID: string
  contractID: string
  tenantAbbreviation: MenuItem
  firstName: string
  lastName: string
  billingState: MenuItem
  vendor: MenuItem
  date: Date | null
  agreements: boolean
}

const init = initValues

const RestockingFeeForm = () => {
  const { info } = useSelector((state: AppState) => state.survey)
  const surveyReplacement = useSelector(
    (state: AppState) => state.replacementSurvey.replacement
  )
  const { counter, formType } = useFormsContext()
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const { isVendorsLoading } = useGetVendors()

  const { submitStripePayment, handleStripeResponse } = usePayments()
  const { status: paymentStatus, errorMsg } = usePaymentContext()
  const submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi> = useRef(null)
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false)
  const [isDispatchLoading, setIsDispatchLoading] = useState<boolean>(false)
  const [dispatchError, setDispatchError] = useState<string>('')
  const [initialTenantAbbreviation, setInitialTenantAbbreviation] = useState<
    MenuItem
  >(emptyItem)
  const [
    isCartAvailabilityModalOpen,
    setIsCartAvailabilityModalOpen,
  ] = useState<boolean>(false)
  const {
    getOptions,
    findTenant,
    findVendor,
    findTenantByLabel,
    findBillingState,
  } = usePaymentOptions()
  const {
    resetShoppingCartData,
    isShoppingCartEmpty,
    shoppingCartData,
    total,
    tax,
    hasCartUnavailableItems,
    lastSku,
    setTenant,
  } = useShoppingCartContext()

  const { status } = usePaymentContext()

  const {
    watch,
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(continueReplacementSchema),
    defaultValues: initValues,
  })

  const contractID = watch('contractID')
  const dispatchID = watch('dispatchID')
  const { id: vendor } = watch('vendor')
  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const billingState = watch('billingState.id')
  const { id: tenantAbbreviation } = watch('tenantAbbreviation')

  const submitForm = async (data: IForm, verify: boolean = false) => {
    if (!isWarningOpen && total > 10000) {
      setIsWarningOpen(true)
    } else if (verify && hasCartUnavailableItems) {
      setIsCartAvailabilityModalOpen(true)
      return
    } else {
      let cart = shoppingCartData.map(({ item, price, unitTax }) =>
        unitTax
          ? {
              ...item,
              ...price,
              unitTax,
            }
          : {
              ...item,
              ...price,
            }
      )

      const dataEntry = {
        cartItems: cart,
        taxDetail: tax,
      }
      const postData = {
        ...data,
        sku: lastSku || '',
        paymentType: formType.id || '',
        cart: dataEntry,
        amount: `${total / 100}`,
        billingState: data.billingState.id,
        vendor: data.vendor.id,
      }
      total === 0
        ? handleStripeResponse(null, postData)
        : submitStripePayment(submitPaymentRef, postData)
    }
  }
  const setAgreements = useCallback((v: boolean) => {
    setValue('agreements', v)
    v && clearErrors('agreements')
    //eslint-disable-next-line
  }, [])

  const setDispatchInfo = async () => {
    reset({ ...initValues, dispatchID })
    resetShoppingCartData()

    if (!dispatchID) return
    setIsDispatchLoading(true)
    try {
      const dispatchInfo = await api.getDispatchInfo(dispatchID)

      if (dispatchInfo?.messages?.length! > 0 && !dispatchInfo?.addressID) {
        setDispatchError(dispatchInfo?.messages?.[0] || '')
        setIsDispatchLoading(false)
        setValue('firstName', '')
        setValue('contractID', '')
        setValue('lastName', '')
        setValue('billingState', emptyItem)
        return
      }

      setValue('firstName', dispatchInfo.firstName || '')
      setValue('contractID', dispatchInfo.contractID || '')
      setValue('lastName', dispatchInfo.lastName || '')
      setValue(
        'billingState',
        findBillingState(dispatchInfo.address?.state || '')
      )
      setInitialTenantAbbreviation(
        findTenantByLabel(dispatchInfo?.tenantAbbreviation?.toUpperCase() || '')
      )

      setValue(
        'tenantAbbreviation',

        findTenantByLabel(dispatchInfo?.tenantAbbreviation?.toUpperCase() || '')
      )

      setIsDispatchLoading(false)
    } catch (e: any) {
      setIsDispatchLoading(false)
      setDispatchError(e.message)
      console.log(e.message)
    }
  }

  useEffect(() => {
    paymentStatus === 'success' && reset(initValues)
    //eslint-disable-next-line
  }, [paymentStatus])
  useEffect(() => {
    if (counter === 0 && info) {
      !firstName &&
        info?.customerName[0] &&
        setValue('firstName', info?.customerName[0])

      !lastName &&
        info?.customerName[1] &&
        setValue('lastName', info.customerName[1])

      !contractID && info?.contractId && setValue('contractID', info.contractId)
      !dispatchID && info?.dispatchId && setValue('dispatchID', info.dispatchId)

      !billingState &&
        info?.billingState &&
        setValue('billingState', findBillingState(info?.billingState || ''))
      setInitialTenantAbbreviation(
        findTenantByLabel(info?.tenantAbbreviation || '')
      )

      !tenantAbbreviation &&
        setValue(
          'tenantAbbreviation',
          findTenantByLabel(info?.tenantAbbreviation || '')
        )
    }
    if (counter === 0 && surveyReplacement) {
      !firstName &&
        setValue('firstName', surveyReplacement.customer?.firstName || '')

      !lastName &&
        setValue('lastName', surveyReplacement.customer?.lastName || '')

      !contractID && setValue('contractID', surveyReplacement.contractID || '')
      !dispatchID && setValue('dispatchID', surveyReplacement.dispatchID || '')

      !billingState &&
        setValue(
          'billingState',
          findBillingState(
            surveyReplacement.customer?.billingAddress?.state ||
              surveyReplacement.customer?.deliveryAddress?.state ||
              ''
          )
        )
      setInitialTenantAbbreviation(
        findTenant(surveyReplacement?.tenantID || '')
      )

      !tenantAbbreviation &&
        setValue(
          'tenantAbbreviation',
          findTenant(surveyReplacement?.tenantID || '')
        )
    }
    //eslint-disable-next-line
  }, [formType])
  useEffect(() => {
    tenantAbbreviation === 'PC' &&
      setValue('vendor', findVendor(tenantAbbreviation))
    setTenant(tenantAbbreviation)
    //eslint-disable-next-line
  }, [tenantAbbreviation])

  useEffect(() => {
    vendor !== 'PC' &&
      initialTenantAbbreviation.id &&
      setValue('tenantAbbreviation', initialTenantAbbreviation)
    //eslint-disable-next-line
  }, [vendor])

  return (
    <div className="overflow-visible">
      <Warning
        isOpen={isWarningOpen}
        setIsOpen={setIsWarningOpen}
        submitForm={() => handleSubmit((data) => submitForm(data, true))()}
      />
      <div className="max-w-sm md:max-w-lg">
        <Controller
          name="dispatchID"
          control={control}
          render={({ field }) => (
            <div className="w-full sm:w-auto md:w-56 mb-6 relative">
              <Input
                id="restocking-fee-form-did"
                error={errors.dispatchID?.message || dispatchError}
                value={field.value}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('DISPATCH_ID_LABEL')}
                className="w-full"
                onChange={(v) => {
                  if (regexDigit.test(v.target.value)) {
                    field.onChange(v)
                    setDispatchError('')
                  }
                }}
                onBlur={field.onBlur}
                disabled={isDispatchLoading}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    setDispatchInfo()
                  }
                }}
              />
              <div
                className="absolute overflow-hidden flex justify-center items-center"
                style={{
                  width: '42px',
                  height: '42px',
                  right: '3px',
                  top: '24px',
                }}
              >
                <IconButton
                  color="interactive"
                  shape="rounded"
                  variant="ghost"
                  disabled={!dispatchID}
                  loading={isDispatchLoading}
                  size="medium"
                  label={localizedText('SURVEY_DISPATCH_SEARCH_BTN')}
                  icon={<IconSearch size="23" />}
                  onClick={setDispatchInfo}
                />
              </div>
            </div>
          )}
        />
        <Controller
          name="contractID"
          control={control}
          render={({ field }) => (
            <div className="w-full sm:w-auto md:w-56 mb-6 relative">
              <Input
                id="restocking-fee-form-on-cid"
                error={errors.contractID?.message}
                value={field.value}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('CONTRACT_ID_LABEL')}
                className="w-full search-custom-input"
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={true}
              />
            </div>
          )}
        />
        {Object.keys(init)
          .filter(
            (v) =>
              v !== 'sku' &&
              v !== 'contractID' &&
              v !== 'agreements' &&
              v !== 'dispatchID'
          )
          ?.map((v: any) => {
            if (
              v !== 'billingState' &&
              v !== 'vendor' &&
              v !== 'date' &&
              v !== 'tenantAbbreviation'
            ) {
              return (
                <Controller
                  key={v}
                  name={v}
                  control={control}
                  render={({ field }) => (
                    <Input
                      id={`restocking-fee-form-input-${v}`}
                      error={errors?.[v]?.message}
                      value={field.value}
                      formField
                      formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                      label={localizedText(labelsKeys[v])}
                      className="w-full"
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      disabled={true}
                    />
                  )}
                />
              )
            } else if (
              v === 'billingState' ||
              v === 'vendor' ||
              v === 'tenantAbbreviation'
            ) {
              return (
                <Controller
                  key={v}
                  name={v}
                  control={control}
                  render={({ field }) => (
                    <Select
                      id={`resctocking-fee-form-select-${v}`}
                      autoComplete={false}
                      error={errors[v]?.message}
                      selected={field.value}
                      formField
                      label={localizedText(labelsKeys[v])}
                      className="w-full md:w-56 mb-6"
                      options={getOptions(v)}
                      onSelect={(e) => {
                        if (v === 'vendor') {
                          e.id === 'PC' &&
                            setValue('tenantAbbreviation', findTenant('PC'))
                        }
                        field.onChange(e)
                      }}
                      onBlur={field.onBlur}
                      placeholder="Select an option"
                      disabled={
                        v === 'billingState' || v === 'tenantAbbreviation'
                          ? true
                          : isVendorsLoading || !isShoppingCartEmpty
                      }
                    />
                  )}
                />
              )
            } else {
              return (
                <Controller
                  key={v}
                  name={v}
                  control={control}
                  render={({ field }) => (
                    <DateTimeInput
                      id={`resctocking-fee-form-date-${v}`}
                      error={errors.date?.message}
                      label={localizedText('OUTRIGHT_PAYMENT_ORDER_DATE_LABEL')}
                      formFieldClassName="w-full sm:w-auto"
                      className="oDate w-full md:w-56 mb-6"
                      selectedDate={field.value}
                      onDateSelect={field.onChange}
                      onBlur={field.onBlur}
                      showMonths={1}
                      maxDate={new Date()}
                      onlyDate
                      datePicker
                      showOutsideMonthDates={true}
                      allowSelectOutsideMonthDates
                      alwaysShowDateClearButton
                    />
                  )}
                />
              )
            }
          })}
        <AddFee propTenant={initialTenantAbbreviation} vendor={vendor} />
      </div>

      <div className="mt-8 mb-6" style={{ maxWidth: 1200 }}>
        <ShoppingCart />
      </div>
      {!isShoppingCartEmpty ? (
        <Agreements updateFormState={setAgreements} />
      ) : null}
      {errors.agreements ? (
        <p className="text-error my-4">{localizedText('ERROR_AGREEMENTS')}</p>
      ) : null}
      {total > 0 ? (
        <div className="my-6">
          <PaymentsForm error={errorMsg} submitPaymentRef={submitPaymentRef} />
        </div>
      ) : null}

      <Button
        id="sku-card-form-submit"
        label={
          total === 0
            ? localizedText('BUTTON_CONTINUE')
            : localizedText('BUTTON_SUBMIT')
        }
        className="w-auto flex justify-center lg:inline-block mt-4"
        onClick={() => handleSubmit((data) => submitForm(data, true))()}
        style={{ lineHeight: 1 }}
        disabled={isShoppingCartEmpty}
        loading={status === 'loading'}
      />
      <CartAvailabilityModal
        callback={() => handleSubmit((data) => submitForm(data))()}
        isOpen={isCartAvailabilityModalOpen}
        toggleModal={setIsCartAvailabilityModalOpen}
      />
    </div>
  )
}

export default RestockingFeeForm
