import {
  Dialog,
  Select,
  SelectHandler,
  SelectOption,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
} from '../custom-fdr-components'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import './reset.css'
import { TShouldConfirm } from 'src/hooks/use-shopping-cart-context'
import { confirmDialogSchema } from './validation'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { MenuItem } from 'src/utils/shared-types'
import { initialValues } from './initialValues'
import { reasons } from './reasons'

interface IProps extends PropsWithChildren<{}> {
  isOpen: boolean
  onClose: () => void
  action: (reason: string) => void
  data?: TShouldConfirm
  message: string
}
interface IForm {
  reason: MenuItem
  isRequired: boolean
}
export const ConfirmDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  action,
  data,
  message,
}) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const {
    control,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(confirmDialogSchema),
    defaultValues: initialValues,
  })
  const [showForm, setShowForm] = useState<boolean>(false)
  const submitForm = (d: IForm) => {
    action(d.reason.value || '')
    reset(initialValues)
  }
  useEffect(() => {
    if (data) {
      setShowForm(data.q === 0 && !/^[A-Z]{3}-CIL$/.test(data.sku))
      data.q === 0 && !/^[A-Z]{3}-CIL$/.test(data.sku)
        ? setValue('isRequired', true)
        : setValue('isRequired', false)
    }
    //eslint-disable-next-line
  }, [data])

  return (
    <Dialog
      id="price-confirmation-dialog"
      open={isOpen}
      onClose={onClose}
      closeOnOutsideClick={true}
      showBackdrop
      overlayClassName="items-center-p"
      actions={[
        <Button size="small" label="Yes" onClick={handleSubmit(submitForm)} />,
        <Button size="small" label="No" onClick={onClose} />,
      ]}
      usePortal={true}
    >
      <>
        <Text textTemplateKey={message} />
        {showForm ? (
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <Select
                id="removal-reson-select"
                error={errors.reason?.message}
                autoComplete={false}
                selected={field.value}
                formField
                label={localizedText('PRICE_REASON_LABEL')}
                className="w-full sm:w-auto md:w-56 mb-0 mt-6"
                options={reasons}
                onSelect={field.onChange as SelectHandler<SelectOption>}
                onBlur={field.onBlur}
                placeholder="Select a reason"
              />
            )}
          />
        ) : null}
      </>
    </Dialog>
  )
}
