import React, { PropsWithChildren, ReactNode, useRef } from 'react'

interface IProps extends PropsWithChildren<{}> {
  content: ReactNode | ReactNode[] | string
}
const Tooltip: React.FC<IProps> = ({ children, content }) => {
  const tooltip = useRef<HTMLDivElement | null>(null)
  const wrapper = useRef<HTMLDivElement | null>(null)

  const onMouseHandler = () => {
    if (!wrapper.current || !tooltip.current) return

    tooltip.current.style.opacity = '1'
    tooltip.current.style.visibility = 'visible'
    tooltip.current.style.display = 'block'
  }

  const onMouseleave = () => {
    if (!wrapper.current || !tooltip.current) return

    tooltip.current.style.opacity = '0'
    tooltip.current.style.visibility = 'invisible'
    tooltip.current.style.display = 'none'
  }
  return (
    <div className="relative" ref={wrapper}>
      <span
        className="block ml-2 cursor-pointer"
        onMouseEnter={onMouseHandler}
        onMouseLeave={onMouseleave}
      >
        {children}
      </span>
      <div
        className="none transition block absolute invisible opacity-0 bg-white  border border-primary-800 p-2 right-0 rounded-2 shadow-sm min-w-full translate-x-1/2"
        style={{ bottom: 20, zIndex: 1000 }}
        ref={tooltip}
      >
        {content}
      </div>
    </div>
  )
}

export default Tooltip
