import { useAppContext } from '@ftdr/blueprint-components-react'

import React, {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

export interface AddedShoppingCartItem {
  sku?: string
  vendor?: string
  unitPrice?: number
  isTax?: boolean
  unitTax?: number
  category?: string
  subcategory?: string
  description?: string
  overrideReason?: string
  originalPrice?: number
  isAvailableForZip?: boolean
}

export type TShouldConfirm = {
  sku: string
  q: number
} | null
interface IShoppingCartItem {
  sku?: string
  vendor?: string
  quantity?: number
  category?: string
  subcategory?: string
  description?: string
  isAvailableForZip?: boolean
  removalReason?: string
}
interface IButton {
  label: string
  currentID?: string
}
interface IInputData {
  sku?: string
}
export interface IShoppingCartData {
  item: IShoppingCartItem
  price: {
    originalPrice?: number
    unitPrice?: number
    overrideReason?: string
  }

  quantity?: number
  unitTax?: number
  total?: number
  button?: IButton
  inputData?: IInputData
}
interface Props {
  shoppingCartData: IShoppingCartData[]
  total: number
  hasNegativeValue: boolean
  setHasNegativeValue: Dispatch<boolean>
  tax: appliancepb.ITaxDetail
  setTax: Dispatch<appliancepb.ITaxDetail>
  setShoppingCartData: (
    data: IShoppingCartData[],
    tax?: appliancepb.ITaxDetail
  ) => void
  resetShoppingCartData: () => void
  currentItem: IShoppingCartData
  setCurrentItem: (id: string) => void
  updateCurrentItemQuantity: (
    id: string,
    quantity: number,
    removalReason?: string
  ) => void
  addItemToShoppingCart: (item: AddedShoppingCartItem) => void
  shouldConfirm: TShouldConfirm
  setShouldConfirm: Dispatch<TShouldConfirm>
  isShoppingCartEmpty: boolean
  vendorServiceCode: string | null
  setVendorServiceCode: Dispatch<string | null>
  hasCartUnavailableItems: boolean
  verifyCartAvailability: () => boolean
  existInCart: (sku: string) => boolean
  lastSku: string | null
  setLastSku: Dispatch<string | null>
  cilValue: number
  updateCilValue: (value: string, sku: string) => void
  appliedCil: number
  hasCartCredit: boolean
  resetCil: () => void
  tenant: string
  setTenant: Dispatch<string>
}
type TShoppingCartContext = {
  children?: React.ReactNode
}
const ShoppingCartContext = createContext<Props>({} as Props)

const ShoppingCartProvider: React.FC<TShoppingCartContext> = ({ children }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const [shouldConfirm, setShouldConfirm] = useState<TShouldConfirm>(null)
  const [total, setTotal] = useState<number>(0)
  const [tax, setTax] = useState<appliancepb.ITaxDetail>({})
  const [shoppingCartData, setShoppingCartData] = useState<IShoppingCartData[]>(
    []
  )
  const [currentItem, setCurrentItem] = useState<IShoppingCartData>(
    {} as IShoppingCartData
  )
  const [hasNegativeValue, setHasNegativeValue] = useState<boolean>(false)
  const [isShoppingCartEmpty, setIsShoppingCartEmpty] = useState<boolean>(false)
  const [vendorServiceCode, setVendorServiceCode] = useState<string | null>(
    null
  )
  const [hasCartUnavailableItems, setHasCartUnavailableItems] = useState<
    boolean
  >(false)
  const [hasCartCredit, setHasCartCredit] = useState<boolean>(false)
  const [lastSku, setLastSku] = useState<string | null>(null)
  const [cilValue, setCilValue] = useState<number>(0)
  const [appliedCil, setAppliedCil] = useState<number>(0)
  const [tenant, setTenant] = useState<string>('')

  const sumAll = (
    data: IShoppingCartData[],
    shouldReapply: boolean = false
  ) => {
    const credit =
      data.find((elem) => elem.item.category === 'CREDIT')?.price
        .originalPrice || 0
    const appliedCredit =
      data.find((elem) => elem.item.category === 'CREDIT')?.total || 0

    const s = data
      .filter((elem) => elem.item.category !== 'CREDIT')
      .reduce((a, b) => a + (b.total || 0), 0)
    const sum = Math.round(s)
    const delta = sum + credit

    if (credit <= 0) {
      if (delta > 0) {
        shouldReapply && setAppliedCil(credit)
        shouldReapply ? setTotal(sum + credit) : setTotal(sum + appliedCredit)
      } else if (delta <= 0) {
        shouldReapply && setAppliedCil(sum > 0 ? sum * -1 : 0)
        shouldReapply ? setTotal(0) : setTotal(sum + appliedCredit)
      }
    }
  }
  const setShoppingCart = (
    data: IShoppingCartData[],
    tax?: appliancepb.ITaxDetail
  ) => {
    tax && setTax(tax)
    setShoppingCartData(data)
    sumAll(data)
  }
  const resetCil = () => {
    setCilValue(0)
    setAppliedCil(0)
  }
  const resetShoppingCartData = () => {
    temp.current = []
    setLastSku('')
    resetCil()
    setTax({})
    setShoppingCartData([])
  }

  const setCurrentItemHandler = (id: string) => {
    const item = shoppingCartData.find((item) => item.item.sku === id)
    if (!item) return
    setCurrentItem(item)
  }
  const existInCart = (sku: string) =>
    !!shoppingCartData.find((elem) => elem.item.sku === sku)

  let temp = useRef<IShoppingCartData[]>([])

  const addItemToShoppingCart = (item: AddedShoppingCartItem) => {
    const {
      sku,
      vendor,
      category,
      subcategory,
      description,
      unitPrice,
      unitTax,
      originalPrice,
      overrideReason,
      isAvailableForZip,
    } = item
    let shouldReapply: boolean = false

    const exist = existInCart(sku || '')

    const itemTax = unitTax || 0

    const price = {
      originalPrice: originalPrice || unitPrice,
      overrideReason,
      unitPrice,
    }

    if (!exist) {
      const newItem = {
        item: {
          sku,
          vendor,
          quantity: 1,
          category,
          subcategory,
          description,
          isAvailableForZip,
        },
        quantity: 1,

        price,

        unitTax: itemTax,
        total: Number((itemTax + Number(unitPrice)).toFixed(2)),

        button: {
          label: /^CIL$/.test(sku || '')
            ? localizedText('SHOPPING_CART_BUTTON_REAPPLY')
            : localizedText('SHOPPING_CART_BUTTON_ADJUST'),
          currentID: sku,
        },
        inputData: { quantity: 1, sku },
      }
      temp.current = [...temp.current, newItem]
      if (/^CIL$/.test(sku || '')) {
        shouldReapply = true
        temp.current =
          price.originalPrice === 0
            ? [...temp.current.filter((item) => item.item.sku !== sku)]
            : [...temp.current]
      }
    } else {
      shouldReapply = false
      temp.current = [...temp.current].map((elem) =>
        elem.item.sku === sku
          ? {
              ...elem,
              item: { ...elem.item, quantity: Number(elem.quantity) + 1 },
              quantity: Number(elem.quantity) + 1,
              inputData: {
                ...elem.inputData,
                quantity: Number(elem.quantity) + 1,
              },
              total: Number(
                (
                  (Number(elem.price.unitPrice) + Number(elem.unitTax)) *
                  (Number(elem.quantity) + 1)
                ).toFixed(2)
              ),
              button: {
                label:
                  Number(unitPrice) >= 0
                    ? localizedText('SHOPPING_CART_BUTTON_ADJUST')
                    : localizedText('SHOPPING_CART_BUTTON_REAPPLY'),
                currentID: sku,
              },
            }
          : elem
      )
    }
    if (/^CIL$/.test(sku || '')) {
      shouldReapply = true
      temp.current =
        price.originalPrice === 0
          ? [...temp.current].filter((v) => v.item.sku !== sku)
          : [...temp.current]
    }
    setShoppingCartData(temp.current)
    sumAll(temp.current, shouldReapply)
    setLastSku(temp.current[temp.current.length - 1]?.item?.sku || null)
  }

  const updateCilValue = (v: string, sku: string) => {
    const value = +v
    let items
    if (value > 0) {
      items = shoppingCartData.map((item) =>
        item.item.sku === sku
          ? {
              ...item,

              price: {
                unitPrice: Number(Number(value).toFixed(2)) * -1,
                originalPrice: Number(Number(value).toFixed(2)) * -1,
              },
              total: Number(Number(value).toFixed(2)) * -1,
            }
          : item
      )
    } else {
      items = shoppingCartData.filter((item) => item.item.sku !== sku)
    }

    if (!items) return
    temp.current = items

    setShoppingCartData(items)

    sumAll(items, true)

    setCilValue(value * -1)
  }

  const updateCurrentItemQuantity = (
    id: string,
    quantity: number,
    removalReason: string = ''
  ) => {
    const items = shoppingCartData.map((item) =>
      item.item.sku === id
        ? {
            ...item,
            item: { ...item.item, quantity, removalReason },
            quantity,
            inputData: { ...item.inputData, quantity },
            total: Number(
              (
                (Number(item.price.unitPrice) + Number(item.unitTax || 0)) *
                Number(quantity)
              ).toFixed(2)
            ),
          }
        : item
    )
    if (!items) return
    temp.current = items
    setShoppingCartData(items)
    sumAll(items) //dont reapply
    const q = temp.current.reduce((a, b) => a + (b.quantity || 0), 0)

    q === 0 && items.length > 0 && setShoppingCartData([])
  }

  const verifyCartAvailability = () => {
    if (
      !!shoppingCartData.find((elem) => {
        return (
          elem.item.category !== 'SERVICE CODE' && !elem.item.isAvailableForZip && elem?.quantity
        )
      })
    ) {
      setHasCartUnavailableItems(true)
      return false
    } else {
      setHasCartUnavailableItems(false)
      return true
    }
  }

  useEffect(() => {
    temp.current = shoppingCartData

    const cilElem = shoppingCartData.find((v) => /^CIL$/.test(v.item.sku || ''))
    cilElem && setCilValue(cilElem.price.originalPrice || 0)
    cilElem && setAppliedCil(cilElem.price.unitPrice || 0)
    cilElem ? setHasCartCredit(true) : setHasCartCredit(false)

    sumAll(shoppingCartData)
    verifyCartAvailability()
      //eslint-disable-next-line
  }, [shoppingCartData])

  useEffect(() => {
    shoppingCartData.length > 0
      ? setIsShoppingCartEmpty(false)
      : setIsShoppingCartEmpty(true)
    const serviceVendorElelemnt = shoppingCartData.find(
      (v) => v.item.vendor === 'AHS' || v.item.vendor === 'HSA'
    )
    serviceVendorElelemnt &&
      setVendorServiceCode(serviceVendorElelemnt.item.vendor || null)
    
    //eslint-disable-next-line
  }, [shoppingCartData.length])

  useEffect(() => {
    const items = shoppingCartData.map((v) =>
      /^CIL$/.test(v.item.sku || '')
        ? {
            ...v,
            item: {
              ...v.item,
              description: `Cash-in-lieu: ${
                cilValue !== 0 ? '$' + cilValue * -0.01 : 0
              }, Applied to Purchase: ${
                appliedCil !== 0 ? '$' + appliedCil * -0.01 : 0
              }`,
            },
            price: {
              unitPrice: Number(appliedCil.toFixed(2)),
              originalPrice: cilValue,
              overrideReason:
                cilValue < appliedCil
                  ? `Cash-in-lieu: ${
                      cilValue !== 0 ? '$' + cilValue * -0.01 : 0
                    }, Applied to Purchase: ${
                      appliedCil !== 0 ? '$' + appliedCil * -0.01 : 0
                    }`
                  : '',
            },
            total: Number(appliedCil.toFixed(2)),
          }
        : v
    )
    setShoppingCartData(items)

    //eslint-disable-next-line
  }, [appliedCil, cilValue])
  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCartData,
        total,
        hasNegativeValue,
        setHasNegativeValue,
        tax,
        setTax,
        setShoppingCartData: setShoppingCart,
        currentItem,
        setCurrentItem: setCurrentItemHandler,
        updateCurrentItemQuantity,
        addItemToShoppingCart,
        shouldConfirm,
        setShouldConfirm,
        resetShoppingCartData,
        isShoppingCartEmpty,
        vendorServiceCode,
        setVendorServiceCode,
        hasCartUnavailableItems,
        verifyCartAvailability,
        existInCart,
        lastSku,
        setLastSku,
        cilValue,
        updateCilValue,
        appliedCil,
        hasCartCredit,
        resetCil,
        tenant,
        setTenant,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}

const useShoppingCartContext = () => useContext(ShoppingCartContext)

export { ShoppingCartProvider, useShoppingCartContext }
