import React from 'react'
import { store, persistor } from 'src/store'
import { PersistGate } from 'redux-persist/integration/react'
import { AppliancePortalRoutes } from 'src/components/appliance-portal-routes'
import { Provider } from 'react-redux'
import { NavBar } from 'src/components/nav-bar'
import { BrowserRouter } from 'react-router-dom'
import { useAuth } from '@ftdr/use-auth'
import { api } from 'src/utils/api'
import { optimizelySdkKeys } from 'src/utils/flag-keys'
import { AlertNotifications } from '../alerts/alert-notifications'
import { MenuProvider } from '../../hooks/use-menu-context'
import { useScrolledContext } from 'src/hooks/use-isScrolled-context'
import { CILModalProvider } from 'src/hooks/use-cil-modal-context'
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import { setLogLevel } from '@optimizely/js-sdk-logging'
import { DispatchInfoProvider } from 'src/hooks/use-is-disptach-info'
import { useMedia } from 'src/hooks/use-media'

export const AppliancePortalApp = () => {
  const { oidcUser } = useAuth()
  const { positionFromTop } = useScrolledContext()
  const optimizelyClient = createInstance({
    sdkKey: optimizelySdkKeys[api.config.ENV],
  })
  const isDesktop = useMedia('(min-width:1024px)')
  setLogLevel('off')

  const optimizelyUser =
    oidcUser?.profile.email?.toLowerCase().split('@')[0] || '@' //Temporary solution: euserEmail and id cannot be empty strings for the variables to work

  return (
    <div>
      {oidcUser !== undefined && (
        <OptimizelyProvider
          optimizely={optimizelyClient}
          user={{
            id: optimizelyUser,
            attributes: { userEmail: optimizelyUser },
          }}
        >
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <BrowserRouter>
                <DispatchInfoProvider>
                  <div>
                    <MenuProvider>
                      <NavBar />
                    </MenuProvider>
                    <AlertNotifications />
                    <CILModalProvider>
                      <div
                        id="container-1"
                        className="px-4 sm:px-10 md:px-24 lg:px-48"
                        style={{
                          paddingTop: isDesktop
                            ? positionFromTop + 40
                            : '2.5rem',
                        }}
                      >
                        <div>
                          <AppliancePortalRoutes />
                        </div>
                      </div>
                    </CILModalProvider>
                  </div>
                </DispatchInfoProvider>
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </OptimizelyProvider>
      )}
    </div>
  )
}
