import React, { useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  Input,
  Select,
  SelectHandler,
  SelectOption,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { useForm, Controller } from 'react-hook-form'
import { emptyItem } from 'src/components/adminForms/initials'
import { MenuItem, MenuItemWithSubCategory } from 'src/utils/shared-types'
import { api } from 'src/utils/api'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import { notFoundFormSchema } from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { isHSA } from 'src/utils/tenant-helper'
import { v4 } from 'uuid'

interface IForm {
  sku: string
  price: string
  isTax: boolean
  category: MenuItemWithSubCategory
  subCategory: MenuItem
  subCategoryOther: MenuItem
  description: string
}
interface IProps {
  onClose: () => void
  sku: string
  vendor: string
  isAvailable: boolean
}
const NotFoundForm: React.FC<IProps> = ({ onClose, sku, vendor, isAvailable }) => {
  const color = isHSA() ? 'primary' : 'interactive'
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { addItemToShoppingCart, tax } = useShoppingCartContext()

  const [categories, setCategories] = useState<MenuItemWithSubCategory[]>([])
  const [subCategoriesByKey, setSubCategoriesByKey] = useState<any[]>([])
  const [selectedSubCategories, setSelectedSubCategories] = useState<
    MenuItem[]
  >([])

  useEffect(() => {
    async function getCategories() {
      try {
        const {
          mappedItemIDs,
          subcategoriesByCategory,
        } = await api.getApplianceCategories(true)

        const categoriesArray = mappedItemIDs.map((category) => {
          const arr = subcategoriesByCategory.filter(
            (subObj) =>
              subObj?.key?.toLowerCase() === category?.name?.toLowerCase()
          )!
          const categoryOption = {
            //TODO: Missing ID and value in some categories - reach out to the backend team about this.
            id: category.ID || v4(),
            value: category.ID || v4(),
            label: category.name as string,
            hasSubcategory:
              arr.length > 0 && arr[0] && arr[0].values!.length > 0,
          }
          return categoryOption
        })
        setCategories(categoriesArray)
        setSubCategoriesByKey(subcategoriesByCategory)
      } catch (err) {
        console.error('get categories failed', err)
      }
    }
    getCategories()
  }, [])

  const initValues = useMemo(
    () => ({
      sku: sku || '',
      description: '',
      price: '',
      isTax: true,
      category: emptyItem,
      subCategory: emptyItem,
      subCategoryOther: emptyItem,
    }),
    [sku]
  )
  const {
    watch,
    handleSubmit,
    resetField,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(notFoundFormSchema),
    defaultValues: initValues,
  })
  const submitForm = (data: IForm) => {
    addItemToShoppingCart({
      sku: data.sku,
      vendor,
      unitPrice: Number(data.price) * 100,
      isTax: data.isTax,
      unitTax: Number(data.price) * Number(tax.taxTotal),
      category: data?.category?.label,
      subcategory:
        data?.subCategory?.id === 'Other'
          ? data.subCategoryOther.id
          : data?.subCategory?.label?.toLowerCase(),
      description: data.description,
      isAvailableForZip: isAvailable,
    })

    onClose()
  }

  const category = watch('category')
  const subCategory = watch('subCategory')

  useEffect(() => {
    resetField('subCategory')
    resetField('subCategoryOther')
    const arr = subCategoriesByKey.filter(
      (subObj) => subObj?.key?.toLowerCase() === category?.label?.toLowerCase()
    )
    if (arr.length > 0) {
      const subCat = arr[0].values.map((v: string) => ({
        id: v,
        label: v,
        value: v,
      }))
      setSelectedSubCategories([
        ...subCat,
        { id: 'Other', value: 'Other', label: 'Other...' },
      ])
    } else {
      setSelectedSubCategories([])
    }
    //eslint-disable-next-line
  }, [category])

  return (
    <div className="max-w-md">
      <Text className="mb-6">
        Product not found in our system. Please add the details as needed.
      </Text>
      <Controller
        name="sku"
        control={control}
        render={({ field }) => (
          <Input
            id="outright-payment-form-sku-1"
            error={errors.sku?.message}
            value={field.value}
            formField
            formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
            label={localizedText('LABEL_SKU')}
            className="w-full"
            disabled={true}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase()
              field.onChange(e)
            }}
            onBlur={field.onBlur}
          />
        )}
      />
      <Controller
        name="category"
        control={control}
        render={({ field }) => (
          <Select
            id="category-outright-payment-form-category"
            autoComplete={false}
            error={errors.category?.message}
            selected={field.value}
            formField
            label={localizedText('LABEL_CATEGORY')}
            className="w-full md:w-56 mb-6"
            options={categories}
            onSelect={field.onChange as SelectHandler<SelectOption>}
            onBlur={field.onBlur}
            placeholder="Select an option"
          />
        )}
      />
      <div className="flex gap-4">
        <Controller
          name="subCategory"
          control={control}
          render={({ field }) => (
            <Select
              id="category-outright-payment-form-subcategory"
              autoComplete={false}
              error={errors.subCategory?.message}
              selected={field.value}
              formField
              label={localizedText('LABEL_SUBCATEGORY')}
              className="w-full md:w-56 mb-6"
              options={selectedSubCategories || emptyItem}
              onSelect={field.onChange as SelectHandler<SelectOption>}
              onBlur={field.onBlur}
              placeholder="Select an option"
              disabled={categories.length === 0}
            />
          )}
        />
        {subCategory?.id === 'Other' && (
          <Controller
            name="subCategoryOther"
            control={control}
            render={({ field }) => (
              <Input
                id="outright-payment-form-subcategory-other"
                error={errors.subCategoryOther?.message}
                value={field.value.label}
                formField
                formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
                label={localizedText('LABEL_SUBCATEGORY')}
                className="w-full"
                onChange={(e) => {
                  field.onChange({
                    id: e.target.value,
                    label: e.target.value,
                    value: e.target.value,
                  })
                }}
                onBlur={field.onBlur}
                disabled={categories.length === 0 || !category.label}
              />
            )}
          />
        )}
      </div>
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Input
            id="outright-payment-form-descritpion"
            error={errors.description?.message}
            value={field.value}
            formField
            formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
            label={localizedText('LABEL_DESCRIPTION')}
            className="w-full"
            onChange={field.onChange}
            onBlur={field.onBlur}
          />
        )}
      />
      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <Input
            id="outright-payment-form-price"
            error={errors.price?.message}
            inputMode="decimal"
            startEnhancer={() => <Text>$</Text>}
            value={field.value}
            formField
            formFieldClassName="w-full sm:w-auto md:w-56 mb-6"
            label={localizedText('LABEL_PRICE')}
            className="w-full"
            onChange={(e) => {
              const val = e.target.value
              let step1Regex
              let step2Regex

              if (sku.includes('CIL')) {
                step1Regex = /^-$/
                step2Regex = /^(-?\d+)(\.(\d{1,2})?)?$/
              } else {
                step1Regex = /^\d+\.?$/
                step2Regex = /^\d+(\.\d{1,2})?$/
              }
              if (val === '' || step1Regex.test(val) || step2Regex.test(val)) {
                field.onChange(e)
              }
            }}
            onBlur={(e) => {
              e.target.value = Number(e.target.value).toFixed(2)
              field.onChange(e)
              field.onBlur()
            }}
          />
        )}
      />
      <Controller
        name="isTax"
        control={control}
        render={({ field }) => (
          <Checkbox
            value=""
            color={color}
            checked={field.value}
            onChange={field.onChange}
            required={false}
            label={localizedText('LABEL_TAX')}
          />
        )}
      />
      <div className="flex mt-8">
        <Button
          label={localizedText('BUTTON_ADD_TO_CART')}
          size="medium"
          onClick={() => {
            handleSubmit(submitForm)()
          }}
        />
        <Button
          className="ml-6"
          label={localizedText('BUTTON_CANCEL')}
          size="medium"
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default NotFoundForm
