import { MenuItem } from '@ftdr/blueprint-components-react'
import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react'

type Props = {
  counter: number
  setCounter: () => void

  formType: MenuItem
  setFormType: Dispatch<MenuItem>
}

interface IFormsContext extends PropsWithChildren<{}> {}

const FormsContext = createContext<Props>({} as Props)

const FormsProvider: React.FC<IFormsContext> = ({ children }) => {
  const [counter, setCounter] = useState<number>(0)
  const [formType, setFormType] = useState<MenuItem>({} as MenuItem)

  return (
    <FormsContext.Provider
      value={{
        counter,
        setCounter: () => setCounter((state) => state + 1),
        formType,
        setFormType,
      }}
    >
      {children}
    </FormsContext.Provider>
  )
}

const useFormsContext = () => useContext(FormsContext)

export { FormsProvider, useFormsContext }
