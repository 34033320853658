import { Checkbox, useAppContext } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import React, { useEffect, useMemo, useState } from 'react'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import { TermsAndConditionsDialog } from 'src/screens/checkout-page/terms-and-conditions-dialog'
// import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { api } from 'src/utils/api'
import { isHSA } from 'src/utils/tenant-helper'

interface IProps {
  updateFormState: (v: boolean) => void
}
interface ICheckbox {
  id: string
  key: string
  value: boolean
  label: string
  quantity?: number
  isItemNotVisible?: boolean
}
export const Agreements: React.FC<IProps> = ({ updateFormState }) => {
  const color = isHSA() ? 'primary' : 'interactive'
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const labels = useMemo(
    () => [
      localizedText('CHECKOUT_TERMS_AND_CONDITIONS_4'),
      localizedText('CHECKOUT_TERMS_AND_CONDITIONS_1'),
    ],

    [localizedText]
  )
  const [showingTermsAndConditions, showTermsAndConditions] = useState(false)
  const [init, setInit] = useState<ICheckbox[]>([])
  const { shoppingCartData } = useShoppingCartContext()

  useEffect(() => {
    if (shoppingCartData.length === 0) return


    const debounceTimer = setTimeout(() => {
    const uniqueSkuSet = Array.from(new Set(shoppingCartData.map(({ item }) => item.sku)));
    const promises = uniqueSkuSet.map((sku) =>
      api.getModel(`${sku}`, { category: shoppingCartData.find(({ item }) => item.sku === sku)?.item.category })
    );
    Promise.all(promises)
      .then((results) => {
        let checkboxes: ICheckbox[] = [
          {
            id: '0',
            value: false,
            key: 'checked1',
            label: labels[1],
          },
          {
            id: '1',
            value: false,
            key: 'checked2',
            label: labels[0],
          },
        ]
        results.reverse().forEach((v, i) => {
          const modelNumber = v.products?.[0]?.modelNumber || ''
          const type = v.products?.[0]?.category || ''
          const x = v.products?.[0]?.dimensions?.width || ''
          const y = v.products?.[0]?.dimensions?.height || ''
          const z = v.products?.[0]?.dimensions?.depth || ''

          const formatedType = type ? type[0] + type.slice(1).toLowerCase() : ''
          const isItemNotVisible = modelNumber
            ? !shoppingCartData.find(
                (v) =>
                  v.item.sku === modelNumber &&
                  v.item.quantity &&
                  v.item.quantity > 0
              )
            : true

          const baseCount = 2
          v.products.length > 0 &&
            checkboxes.push({
              id: `${baseCount + i}`,
              value: false,
              label: localizedText('CHECKOUT_AGREEMENTS_PRODUCT_DIMENSIONS', {
                type: formatedType,
                x,
                y,
                z,
              }),
              key: `checked${baseCount + i + 1}`,
              isItemNotVisible,
            })
        })

        setInit(checkboxes.reverse())
      })
      .catch((e) => {
        console.log(e)
      })
    }, 500)

    return () => clearTimeout(debounceTimer)
    //eslint-disable-next-line
  }, [shoppingCartData])

  useEffect(() => {
    const isNotError =
      init.length > 0 &&
      init.filter((v) => v.value === false && !v.isItemNotVisible).length === 0
    isNotError ? updateFormState(true) : updateFormState(false)
    //eslint-disable-next-line
  }, [init])
  return (
    <>
      {showingTermsAndConditions ? (
        <>
          <TermsAndConditionsDialog
            onClose={() => showTermsAndConditions(false)}
            isGiftCard={false}
          />
        </>
      ) : null}
      {init.length > 0 ? (
        <Text variant="heading-04" className="mb-4">
          Agreements
        </Text>
      ) : null}
      {init.length > 0
        ? init.map(({ key, id, value, label, isItemNotVisible }) => (
            <div
              key={id}
              className="mb-4"
              style={{ opacity: isItemNotVisible ? 0.5 : 1 }}
            >
              <div className="flex items-center">
                <Checkbox
                  required={!isItemNotVisible}
                  value={'1'}
                  disabled={isItemNotVisible}
                  color={color}
                  checked={value}
                  onChange={() => {
                    setInit((prevState) => [
                      ...prevState.map((v) =>
                        v.key === key ? { ...v, value: !v.value } : v
                      ),
                    ])
                  }}
                  label={label}
                />
                {label === localizedText('CHECKOUT_TERMS_AND_CONDITIONS_1') ? (
                  <button
                    id="continue-replacement-condition-link"
                    className="underline ml-1 inline-block text-body-long line-clamp-none checkbox-label-text"
                    onClick={() => showTermsAndConditions(true)}
                  >
                    {localizedText('CHECKOUT_TERMS_AND_CONDITIONS_LINK')}
                  </button>
                ) : null}
              </div>
            </div>
          ))
        : null}
    </>
  )
}
