import React from 'react'
import ReactDOM from 'react-dom'
import { AppliancePortalAuth } from './components/appliance-portal-app'
import { api } from './utils/api'
//import './global.css'

api.loadConfig().finally(() => {
  ReactDOM.render(
    (<AppliancePortalAuth />) as any,
    document.getElementById('root') as HTMLElement
  )
})
