import { useAuth } from '@ftdr/use-auth'
import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  PropsWithChildren,
  useEffect,
} from 'react'
import { useLocation } from 'react-router-dom'
import { useMedia } from './use-media'

type Props = {
  isDispatchInfoVisible: boolean
  setIsDispatchInfoVisible: Dispatch<boolean>
}
interface TSelectedSlideContext extends PropsWithChildren<{}> {}

const DispatchInfoContext = createContext<Props>({} as Props)

const DispatchInfoProvider: React.FC<TSelectedSlideContext> = ({
  children,
}) => {
  const isDesktop = useMedia('(min-width:1024px)')
  const { pathname } = useLocation()
  const { oidcUser } = useAuth()
  const isCustomer = oidcUser === null
  const [isDispatchInfoVisible, setIsDispatchInfoVisible] = useState<boolean>(
    false
  )

  useEffect(() => {
    const isDispatchInfoVisible =
      !isCustomer &&
      isDesktop &&
      (pathname === '/browse' || /\/shop$/.test(pathname))
        ? true
        : false

    setIsDispatchInfoVisible(isDispatchInfoVisible)
    //eslint-disable-next-line
  }, [oidcUser, isDesktop, pathname])
  return (
    <DispatchInfoContext.Provider
      value={{ isDispatchInfoVisible, setIsDispatchInfoVisible }}
    >
      {children}
    </DispatchInfoContext.Provider>
  )
}

const useDispatchInfoContext = () => useContext(DispatchInfoContext)

export { DispatchInfoProvider, useDispatchInfoContext }
